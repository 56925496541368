// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-hek-293-paper-tsx": () => import("./../../../src/pages/hek293_paper.tsx" /* webpackChunkName: "component---src-pages-hek-293-paper-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-u-2-os-cellmap-data-tsx": () => import("./../../../src/pages/u2os-cellmap-data.tsx" /* webpackChunkName: "component---src-pages-u-2-os-cellmap-data-tsx" */),
  "component---src-pages-u-2-os-cellmap-js": () => import("./../../../src/pages/u2os-cellmap.js" /* webpackChunkName: "component---src-pages-u-2-os-cellmap-js" */),
  "component---src-pages-u-2-os-cellmap-tools-tsx": () => import("./../../../src/pages/u2os-cellmap-tools.tsx" /* webpackChunkName: "component---src-pages-u-2-os-cellmap-tools-tsx" */),
  "component---src-templates-doc-template-js": () => import("./../../../src/templates/docTemplate.js" /* webpackChunkName: "component---src-templates-doc-template-js" */)
}

